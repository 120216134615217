/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

// Inject YouTube API script
var tag = document.createElement('script');
tag.src = "//www.youtube.com/player_api";
var firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

var player;
function onPlayerStateChange(event) {
  if (event.data == 1) {
    document.getElementById('splash-video').style.opacity = 1;
  }
}
function onYouTubePlayerAPIReady() {
  // create the global player from the specific iframe (#video)
  player = new YT.Player('splash-video', {
      events: {
        'onStateChange': onPlayerStateChange
      }
  });
}

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        var offset;
        offset = 100;

        window.viewportUnitsBuggyfill.init();
        if (document.getElementById('patched-viewport')) {
          window.addEventListener('resize', viewportUnitsBuggyfill.refresh, true);
        }

        $('a[href*="#"]:not([href="#"],[data-slide="prev"],[data-slide="next"],[data-toggle="collapse"],[data-toggle="tab"],[href="#tab"])').click(function() {
          if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
            var target = $(this.hash);
            target = (target.length ? target : $('[name=' + this.hash.slice(1) +']'));
            if (target.length) {
              $('html,body').animate({
                scrollTop: target.offset().top - offset
              }, 1000);
              $('.navbar-collapse.in').removeClass("in");
              return false;
            }
          }
        });

        $('.btn-popover').on('click', function(e){
          e.preventDefault();
          $(this).parent().toggleClass('open');
          $('.popover-content').toggle();
        });

        $('.expanding_text .collapse').on('show.bs.collapse', function (e) {
          $(this).siblings('.btn-text').text('Close');
        });

        $('.expanding_text .collapse').on('hide.bs.collapse', function (e) {
          $(this).siblings('.btn-text').text('More');
        });

        if($(".people_grid .person").length){
          $(".person").on("click", function(){
            var source_id = $(this).data("target");
            var source = $(source_id);
            var title = $(this).find('.person-title h2').html();
            var body = source.html();
            var popup = $('#modal-popup');
            popup.find('.modal-title').html(title);
            popup.find('.modal-body').html(body);
            popup.modal("show");
         });
        }

        // animate search box on click .fa-search icon
        /*$(window).click(function() {
          $('.search').show();
          $('.search-box').hide();
        });*/

        if($('.search').is(':visible')){
          $('.search > a').on('click', function(e) {
            e.stopPropagation();
            $(this).parent('.search').hide();
            $('.search-box').show();
            $('.search-box').find('input').focus();
          });
        }

        var mapstyle = [
          {
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#f5f5f5"
              }
            ]
          },
          {
            "elementType": "labels.icon",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#616161"
              }
            ]
          },
          {
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "color": "#f5f5f5"
              }
            ]
          },
          {
            "featureType": "administrative",
            "stylers": [
              {
                "visibility": "on"
              }
            ]
          },
          {
            "featureType": "administrative.country",
            "stylers": [
              {
                "visibility": "on"
              }
            ]
          },
          {
            "featureType": "administrative.land_parcel",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#bdbdbd"
              }
            ]
          },
          {
            "featureType": "administrative.locality",
            "stylers": [
              {
                "visibility": "on"
              }
            ]
          },
          {
            "featureType": "administrative.neighborhood",
            "stylers": [
              {
                "visibility": "on"
              }
            ]
          },
          {
            "featureType": "administrative.province",
            "stylers": [
              {
                "visibility": "on"
              }
            ]
          },
          {
            "featureType": "landscape.man_made",
            "stylers": [
              {
                "visibility": "on"
              }
            ]
          },
          {
            "featureType": "landscape.natural",
            "stylers": [
              {
                "visibility": "on"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#eeeeee"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#757575"
              }
            ]
          },
          {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#e5e5e5"
              }
            ]
          },
          {
            "featureType": "poi.park",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#9e9e9e"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#ffffff"
              }
            ]
          },
          {
            "featureType": "road.arterial",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#757575"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#dadada"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#616161"
              }
            ]
          },
          {
            "featureType": "road.local",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#9e9e9e"
              }
            ]
          },
          {
            "featureType": "transit.line",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#e5e5e5"
              }
            ]
          },
          {
            "featureType": "transit.station",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#eeeeee"
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#c9c9c9"
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#9e9e9e"
              }
            ]
          }
        ];
        var polylines = [];
        var paths = [];
        var markers = [];
        var markerGroups = {
          "acl": [],
          "acm": [],
          "afm": [],
          "cmg": [],
          "ecm": [],
          "fa": [],
          "flo": [],
          "hls": [],
          "lbe": [],
          "vo": []
        };
        var allMarkers = [];
        var infoWindows = [];
        var map;
        var markerIcons = {
          acl: {
            path: google.maps.SymbolPath.CIRCLE,
            fillColor: '#d64a03',
            fillOpacity: 0.75,
            scale: 6,
            strokeColor: 'white',
            strokeWeight: 1
          },
          acm: {
            path: google.maps.SymbolPath.CIRCLE,
            fillColor: '#9e5400',
            fillOpacity: 0.75,
            scale: 6,
            strokeColor: 'white',
            strokeWeight: 1
          },
          afm: {
            path: google.maps.SymbolPath.CIRCLE,
            fillColor: '#008da8',
            fillOpacity: 0.75,
            scale: 6,
            strokeColor: 'white',
            strokeWeight: 1
          },
          cmg: {
            path: google.maps.SymbolPath.CIRCLE,
            fillColor: '#00938d',
            fillOpacity: 0.75,
            scale: 6,
            strokeColor: 'white',
            strokeWeight: 1
          },
          ecm: {
            path: google.maps.SymbolPath.CIRCLE,
            fillColor: '#7d321a',
            fillOpacity: 0.75,
            scale: 6,
            strokeColor: 'white',
            strokeWeight: 1
          },
          fa: {
            path: google.maps.SymbolPath.CIRCLE,
            fillColor: '#005254',
            fillOpacity: 0.75,
            scale: 6,
            strokeColor: 'white',
            strokeWeight: 1
          },
          flo: {
            path: google.maps.SymbolPath.CIRCLE,
            fillColor: '#989300',
            fillOpacity: 0.75,
            scale: 6,
            strokeColor: 'white',
            strokeWeight: 1
          },
          hls: {
            path: google.maps.SymbolPath.CIRCLE,
            fillColor: '#556c08',
            fillOpacity: 0.75,
            scale: 6,
            strokeColor: 'white',
            strokeWeight: 1
          },
          lbe: {
            path: google.maps.SymbolPath.CIRCLE,
            fillColor: '#ad7e08',
            fillOpacity: 0.75,
            scale: 6,
            strokeColor: 'white',
            strokeWeight: 1
          },
          vo: {
            path: google.maps.SymbolPath.CIRCLE,
            fillColor: '#872c37',
            fillOpacity: 0.75,
            scale: 6,
            strokeColor: 'white',
            strokeWeight: 1
          }
        };

        /**
         * Function to init map
         */
        /* define mapDiv before initMap */
        var mapDiv = document.getElementById('map');
        var minZoomLevel = 5;

        function closeAllInfoWindows() {
          for (var i=0;i<infoWindows.length;i++) {
             infoWindows[i].close();
          }
        }
        function setMarkers(map){
          if(typeof(local.map) !== 'undefined'){

            var bounds = new google.maps.LatLngBounds();

            for (var j = local.map.markers.length-1; j >= 0; j--) {
              var mapMarker = local.map.markers[j];
              var markerPosition = new google.maps.LatLng(mapMarker[1], mapMarker[2]);
              var markerIcon = mapMarker[4];
              var index = '';
              bounds.extend(markerPosition);

              if( !~ markerIcon.indexOf('http') ){
                markerIcon = {
                  path: google.maps.SymbolPath.CIRCLE,
                  fillColor: mapMarker[4],
                  fillOpacity: 0.75,
                  scale: 6,
                  strokeColor: 'white',
                  strokeWeight: 1
                };
              }
              var marker = new google.maps.Marker({
                position: markerPosition,
                map: map,
                title: mapMarker[0],
                zIndex: mapMarker[3],
                icon: markerIcon
              });
              allMarkers.push(marker);
              if(mapMarker[7] && markerPosition){
                markers[mapMarker[7]].push(marker);
                paths[mapMarker[7]].push(markerPosition);
              }
              var infowindow = new google.maps.InfoWindow();
              infoWindows.push(infowindow);

              if(mapMarker[8]){
                if(mapMarker[8].length > 1 && marker){
                  if (mapMarker[8].indexOf(',') > -1) {
                    var markerCategories = mapMarker[8].split(',');
                    for (var i = 0; i < markerCategories.length; i++) {
                      index = markerCategories[i];
                      markerGroups[index].push(marker);
                    }
                  }else{
                    index = mapMarker[8];
                    markerGroups[index].push(marker);
                  }
                }
              }

              var content = '<h4>'+mapMarker[0]+'</h4>'+
              (mapMarker[5]?'<p>'+mapMarker[5]:'')+'</p><p>'+
              (mapMarker[6]?'<a href="'+
              mapMarker[6]+'">Learn more &raquo;</a>':'')+
              '</p>';
              google.maps.event.addListener(marker,'click', (function(marker,content,infowindow){
                return function() {
                  closeAllInfoWindows();
                  infowindow.setContent(content);
                  infowindow.open(map,marker);
                };
              })(marker,content,infowindow));
            }

            if(local.map.markers.length > 1){
              map.fitBounds(bounds);
            }

          }
        }
        var showMarkers = function(subjectId) {
          var subjectMarkers = markers[subjectId];
          polylines[subjectId].setMap(map);
          for (var i = subjectMarkers.length-1;  i >= 0; i--) {
            subjectMarkers[i].setMap(map);
          }
        };
        var hideMarkers = function(subjectId) {
          var subjectMarkers = markers[subjectId];
          polylines[subjectId].setMap(null);
          for (var i = subjectMarkers.length-1;  i >= 0; i--) {
            subjectMarkers[i].setMap(null);
          }
        };


        $(function initMap() {
          /* test if mapDiv exists before initialising map */

          if(mapDiv){

            map = new google.maps.Map(mapDiv, {
              center: {lat: (local.map.latitude === undefined ? 57 : local.map.latitude), lng: (local.map.longitude === undefined ? -4.35 : local.map.longitude)},
              zoom: (local.map.zoom === undefined ? 7 : parseInt(local.map.zoom)),
              scrollwheel: false,
              mapTypeId: (local.map.type === undefined ? 'terrain' : local.map.type),
              styles: mapstyle
            });

            setMarkers(map);
          }
        });

        /**
         * Function to filter markers by category
         */
        function toggleGroup(sector) {
          var marker;
          for(var j = allMarkers.length -1; j >= 0; j--){
            marker = allMarkers[j];
            marker.setVisible(false);
          }
          for (var i = markerGroups[sector].length -1; i >=0; i--) {
            marker = markerGroups[sector][i];
            if (!marker.getVisible()) {
              marker.setVisible(true);
              marker.setIcon(markerIcons[sector]);
            } else {
              marker.setVisible(false);
            }
          }
        }

        $('#sector-filter li').on('click',function(){
          var sector = $(this).attr('id');
          $(this).siblings().removeClass('show-sector');
          $(this).addClass('show-sector');
          toggleGroup(sector);
        });

        /**
         * query string
         * @param  {[type]} a) {                       if (a [description]
         * @return {[type]}    [description]
         */
        var qs = (function(a) {
            if (a === ""){
              return {};
            }
            var b = {};
            for (var i = 0; i < a.length; ++i)
            {
                var p=a[i].split('=', 2);
                if (p.length === 1){
                  b[p[0]] = "";
                }else{
                  b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
                }
            }
            return b;
        })(window.location.search.substr(1).split('&'));

        $('#post-filter').val("");

        function keywordSearch(){
          $(".post-item").removeClass("result");
          var searchStringLength  = $('#post-filter').val().length;
          if(searchStringLength > 2){
            $('.post-item').not(".activefilter").each(function () {
              var searchText = $('#post-filter').val().toLowerCase().trim();
              var keywordContent = $(this),
                  text  = $(this).text(),
                  textL = text.toLowerCase();

              if(textL.indexOf(searchText) !== -1){
                keywordContent.addClass("result").removeClass('hidden');
              }else{
                keywordContent.removeClass("result").addClass('hidden');
              }
              });
          }else{
            $(".post-item").not(".activefilter").not(".paged").removeClass('hidden');
          }
          var count = $('.post-item').not('.activefilter').length;
          $('.post-count').text(count).data('count', count);
        }

        var timer = 0;
        $('#post-filter').on('keyup', function() {
          if (timer) {
            clearTimeout(timer);
          }
          timer = setTimeout(keywordSearch, 400);
        });

        if($('.post-filters').length){

          var limitResults = function($multiplier) {
            if ($multiplier === undefined) {
              $multiplier = 1;
            } 
            var $courseCount = 0;
            var $courseLimit = 24 * $multiplier;
            var $courseResults = parseInt($('.post-count').data('count'));

            $('.post-item:not(".activefilter")').each(function(){
              if($courseCount >= $courseLimit){
                $(this).addClass('hidden paged');
              }else{
                $(this).removeClass('hidden');
              }
              $courseCount++;
            });

            // limit displayed results to 25
            console.log($courseResults + '>' + $courseLimit);
            if($courseResults > $courseLimit){
              if(!$('#loadMore').length){
                $('.post_list').append('<button class="btn btn-primary mx-auto" id="loadMore">Load More</button>');
              }
            }else{
              $('#loadMore').remove();
            }
          }
          limitResults(1);

          var postFilters = function() {

            $('.post_list article').addClass('hidden activefilter');
            var activeSector = [];
            var activeNation = [];
            var activeAudience = [];

            $('.post-filters .active-sector input[type=checkbox]:checked').each(function(index){
              activeSector.push($(this).val());
            });
            $('.post-filters .active-nation input[type=checkbox]:checked').each(function(index){
              activeNation.push($(this).val());
            });
            $('.post-filters .active-audience input[type=checkbox]:checked').each(function(index){
              activeAudience.push($(this).val());
            });

            if(activeSector[0] == null && activeNation[0] == null && activeAudience[0] == null){
              $('.post_list article').removeClass('hidden activefilter');
            }else{
              $('.post_list article').each(function(){
                var $has_sector = 0;
                var $has_nation = 0;
                var $has_audience = 0;
                for (var i = activeSector.length; i--; ) {
                  if ($(this).data('sector').includes('.'+activeSector[i])){
                    $has_sector++;
                  }
                }
                for (var j = activeNation.length; j--; ) {
                  if ($(this).data('nation').includes('.'+activeNation[j])){
                    $has_nation++;
                  }
                }
                for (var k = activeAudience.length; k--; ) {
                  if ($(this).data('audience').includes('.'+activeAudience[k])){
                    $has_audience++;
                  }
                }

                if(activeSector.length > 0 && activeNation.length > 0 && activeAudience.length > 0){
                  if($has_sector > 0 && $has_nation > 0 && $has_audience > 0){
                    $(this).removeClass('hidden activefilter');                   
                  }
                }else if(activeSector.length > 0 && activeNation.length > 0 && activeAudience[0] == null){
                  if( $has_sector > 0 && $has_nation > 0){
                    $(this).removeClass('hidden activefilter');                   
                  }
                }else if(activeSector.length > 0 && activeNation[0] == null && activeAudience.length > 0){
                  if( $has_sector > 0 && $has_audience > 0){
                    $(this).removeClass('hidden activefilter');                   
                  }
                }else if(activeSector[0] == null && activeNation.length > 0 && activeAudience.length > 0){
                  if( $has_nation > 0 && $has_audience > 0){
                    $(this).removeClass('hidden activefilter');                   
                  }
                }else{
                  if($has_sector > 0 || $has_nation > 0 || $has_audience > 0){
                    $(this).removeClass('hidden activefilter');                   
                  }
                }
              });
            }

            if (timer) {
              clearTimeout(timer);
            }
            timer = setTimeout(keywordSearch, 400);

            var count = $('.post-item').not(".hidden").length;
            $('.post-count').text(count).data('count',count);

            limitResults(1);
          };
          postFilters();

          $('body').on('click', '.post_list #loadMore', function(){
            var batch = Math.floor($('.post-item').not(".hidden").length / 24) + 1;
            console.log(batch);
            limitResults(batch);
          });

          $('body').on('click', '.post-filters input[type=checkbox]', function(){
            postFilters();
          });

          if(qs.sector || qs.nation || qs.audience){
            if(qs.sector){
              var arrPreSelectedSector = qs.sector.split(',');
              if(arrPreSelectedSector.length > 0){
                for( i = arrPreSelectedSector.length; i--; ){
                  $('#sector-'+arrPreSelectedSector[i]).attr('checked', true);
                }
              }
            }
            if(qs.nation){
              var arrPreSelectedNation = qs.nation.split(',');
              if(arrPreSelectedNation.length > 0){
                for( j = arrPreSelectedNation.length; j--; ){
                  $('#nation-'+arrPreSelectedNation[j]).attr('checked', true);
                }
              }
            }
            if(qs.audience){
              var arrPreSelectedAudience = qs.audience.split(',');
              if(arrPreSelectedAudience.length > 0){
                for( k = arrPreSelectedAudience.length; k--; ){
                  $('#audience-'+arrPreSelectedAudience[k]).attr('checked', true);
                }
              }
            }
            postFilters();
          }
        }

        if(qs.keywords){
          $('#post-filter').val(qs.keywords);
          keywordSearch();
        }

        function displayNation(position) {
          /* get nation from geonames */
          $.getJSON('//maps.googleapis.com/maps/api/geocode/json', {
              latlng: position.coords.latitude+','+position.coords.longitude
          }, function(result) {
            var nation = result.results[0].address_components[5].long_name;
            if(nation.toLowerCase() === 'united kingdom'){
              nation = result.results[0].address_components[4].long_name;
            }
            var nationLC = nation.toLowerCase();
            $('#nation-'+nationLC).click();
            $('#post-results').prepend('<div class="well auto-filter" id="nation-auto-filter"><p><strong>Showing results for '+nation+'</strong><br><small><i class="fa fa-fw fa-info-circle" aria-hidden="true"></i> Use the <a href="#post-list">filters to adjust your search</a>.</small></p></div>');
          });
        }

        if($('.post-filters').length && $('.page.college-university-courses').length < 1){
          if(navigator.geolocation){
            navigator.geolocation.getCurrentPosition(displayNation);
          }​
        }

        $('.active-nation input[type=checkbox]').on('click',function(){
          $('#nation-auto-filter strong').text('Showing filtered results');
          postFilters();
        });

        if($('#post-location'.length)){

          // JavaScript to be fired on the about us page
          var x = document.getElementById("coordinates");
          var placeSearch, autocomplete;

          function locationNotification(text){
            if($("#location-order").length){
              $('#location-order').html(text);
            }else{
              $('#college-and-courses-intro').after('<p id="location-order">'+text+'</p>');
            }
          }

          function getHaversineDistance(lat1,lng1,lat2,lng2,unit) {
            unit = unit || 'km';
              // Converts degrees to Rads

            if (typeof(Number.prototype.toRad) === "undefined") {
              Number.prototype.toRad = function() {
                return this * Math.PI / 180;
              };
            }

            var R = '';
            if (unit === 'km') {
              R = 6371; // km
            } else {
              R = 3959; // mi
            }
            var dLat = (parseFloat(lat2)-parseFloat(lat1)).toRad();
            var dLon = (parseFloat(lng2)-parseFloat(lng1)).toRad();
            var dlat1 = parseFloat(lat1).toRad();
            var dlat2 = parseFloat(lat2).toRad();

            var a = Math.sin(dLat/2) * Math.sin(dLat/2) + Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(dlat1) * Math.cos(dlat2);
            var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
            var d = R * c;

            return d;
          }

          function addDistance(originLat, originLng){
            $("article.post-item").each(function(){
              var postLat = $(this).data('lat');
              var postLng = $(this).data('lng');
              var distanceFromOrigin = getHaversineDistance(originLat,originLng,postLat,postLng,"km");
              if(isNaN(distanceFromOrigin)){
                distanceFromOrigin = 999;
              }
              $(this).attr("data-distance", Number(distanceFromOrigin).toFixed(2));
            });

            var $wrapper = $('.post-list');
            if($wrapper.length){
              $('.post-list .post-item').removeClass('hidden');
              $wrapper.find('article').sort(function(a, b) {
                  return +a.getAttribute('data-distance') - +b.getAttribute('data-distance');
              })
              .appendTo($wrapper);
              limitResults(1);
            }
          }

          function getCoordinates() {
            // Get the place details from the autocomplete object.
            var place = autocomplete.getPlace();

            if(place.formatted_address){
              locationNotification('Courses listed in order of proximity to <strong>'+place.formatted_address+'<strong>');
              addDistance(place.geometry.location.lat(), place.geometry.location.lng());
            }
          }

          function initAutocomplete() {
            // Create the autocomplete object, restricting the search to geographical
            // location types.
            autocomplete = new google.maps.places.Autocomplete(
                /** @type {!HTMLInputElement} */(document.getElementById('post-location')),
                {types: ['geocode']});

            // When the user selects an address from the dropdown, populate the address
            // fields in the form.
            var bounds = new google.maps.LatLngBounds(
                new google.maps.LatLng(9.383639452689664, -17.39866406249996),
                new google.maps.LatLng(59.53530451232491, 8.968523437500039));

            autocomplete.setBounds(bounds);
            autocomplete.addListener('place_changed', getCoordinates);
          }
          $("#post-location").on("click",initAutocomplete());

          function getCurrentPosition(position) {
            addDistance(position.coords.latitude, position.coords.longitude);
            var currentLocation;
            var latlng = {lat: parseFloat(position.coords.latitude), lng: parseFloat(position.coords.longitude)};
            var geocoder = new google.maps.Geocoder();
            geocoder.geocode({'location': latlng}, function(results, status) {
              if (status === 'OK') {
                if (results[1]) {
                  currentLocation = 'Courses listed in order of proximity to <strong>'+results[1].formatted_address+'<strong>';
                  $('#post-location').val(results[1].formatted_address);

                  var nation = results[0].address_components[5].long_name;
                  var nationLC = nation.toLowerCase();
                  $('#nation-'+nationLC).click();
                  $('#post-results').prepend('<div class="well auto-filter" id="nation-auto-filter"><p><strong>Showing results for '+nation+'</strong><br><small><i class="fa fa-fw fa-info-circle" aria-hidden="true"></i> Use the <a href="#post-list">filters to adjust your search</a>.</small></p></div>');
                } else {
                  currentLocation = 'No results found';
                }
              } else {
                window.alert('Geocoder failed due to: ' + status);
                return;
              }
              locationNotification(currentLocation);
            });
          }

          function getLocation() {
            if (navigator.geolocation) {
              navigator.geolocation.getCurrentPosition(getCurrentPosition);
            } else {
              locationNotification("Geolocation is not supported by this browser.");
            }
          }
          getLocation();

        }

        /* copy loaded carousel images into thumbnails */
        $('.carousel .show-thumbnails').each(function(i){

          var carouselId = $(this).find(".carousel").attr('id');

          var containerId = 'cthumbs-' + Math.floor((Math.random() * 100) + 1);
          var thumbnailContainer = $('<div class="carousel-thumbnails" id="cthumbs-'+
            containerId + '"></div>');
          thumbnailContainer.appendTo( $(this) );
          var dataIndex = 0;
          $('.carousel .show-thumbnails .carousel-inner div.item').each(function(i) {

            var item = $('<a data-gallery="gallery" href="#'+carouselId+'" data-index="'+
              dataIndex+'"></a>');
            var innerItem = $('<div class="thumbnail"></div>');
            var style = $(this).attr('style');

            innerItem.attr("style",style);
            innerItem.appendTo( item );
            item.appendTo( thumbnailContainer );
            dataIndex += 1;
          });
        });


        /* when clicking a thumbnail */
        $('.carousel-thumbnails a').click(function(e){
          var idx = $(this).data("index");
          var id = parseInt(idx);
          $(this).parent().siblings(".carousel.slide").carousel(id); // slide carousel to selected
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        // Scroll college logos
        $(function(){
          var scroller = $('.scroller-block');
          var scrollerContent = scroller.children('ul');
          scrollerContent.children().clone().appendTo(scrollerContent);
          var curX = 0;
          scrollerContent.children().each(function(){
              var $this = $(this);
              $this.css('left', curX);
              curX += $this.outerWidth(true);
          });
          var fullW = curX / 2;
          var viewportW = scroller.width();

          // Scrolling speed management
          var controller = {curSpeed:0, fullSpeed:2};
          var $controller = $(controller);
          var tweenToNewSpeed = function(newSpeed, duration)
          {
              if (duration === undefined){
                  duration = 600;
          }
              $controller.stop(true).animate({curSpeed:newSpeed}, duration);
          };

          // Pause on hover
          scroller.hover(function(){
              tweenToNewSpeed(0);
          }, function(){
              tweenToNewSpeed(controller.fullSpeed);
          });

          // Scrolling management; start the automatical scrolling
          var doScroll = function()
          {
              var curX = scroller.scrollLeft();
              var newX = curX + controller.curSpeed;
              if (newX > fullW*2 - viewportW){
                  newX -= fullW;
          }
              scroller.scrollLeft(newX);
          };
          setInterval(doScroll, 20);
          tweenToNewSpeed(controller.fullSpeed);
        });

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        $(window).scroll(function() {
          var hT = $('.center-logo').offset().top,
              hH = $('.center-logo').outerHeight(),
              wH = $(window).height(),
              wS = $(this).scrollTop();
          if (wS > (hT+hH-wH)){
            $('.icon-leaf').addClass('active');
          }
        });

        if($('.block-statistics').length){
          $(window).scroll(function() {
            var hT = $('.center-logo').offset().top,
                hH = $('.center-logo').outerHeight(),
                wH = $(window).height(),
                wS = $(this).scrollTop();
            if (wS > (hT+hH-wH)){
              $('.icon-leaf').addClass('active');
            }
          });

          function startCounter() {
            var hT = $('.block-statistics').offset().top,
                hH = $('.block-statistics').outerHeight(),
                wH = $(window).height(),
                wS = $(this).scrollTop();

            if (wS > (hT+hH-wH)) {
              $(window).off("scroll", startCounter);
              $('.stat-number').each(function () {
                var $this = $(this);
                $({ Counter: 0 }).animate({ Counter: $this.text() }, {
                    duration: 1000,
                    easing: 'swing',
                    step: function () {
                      $this.text(Math.ceil(this.Counter));
                    }
                });
              });
            }
          }
          $(window).scroll(startCounter);
        }

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Geocoding page
    'geocoding': {
      init: function() {
      },
      finalize: function() {
        // JavaScript to be fired on the page, after the init JS
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
